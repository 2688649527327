import { MutationTree } from "vuex"
import type { State } from "./state"
import helperFunction from "/src/helper-functions/helperFunctions"
const { getShadeColor } = helperFunction()
export enum MutationType {
    SET_WHITELABEL = "SET_WHITELABEL",
    SET_BRAND_COLOR = "SET_BRAND_COLOR",
    SET_WORKSPACE_ID = "SET_WORKSPACE_ID",
    SET_WORKSPACE_URL = "SET_WORKSPACE_URL",
    SET_WORKSPACE_NAME = "SET_WORKSPACE_NAME",
    SET_WORKSPACE_LOGO = "SET_WORKSPACE_LOGO",
    SET_WORKSPACE_FAVICON = "SET_WORKSPACE_FAVICON",
    SET_SUBSCRIPTION_EXPIRE_DATE = "SET_SUBSCRIPTION_EXPIRE_DATE",
    SET_WORKSPACE_STATUS = "SET_WORKSPACE_STATUS",
    SET_IS_BILLING_ALLOWED = "SET_IS_BILLING_ALLOWED",
    SET_IS_TRIAL = "SET_IS_TRIAL",
    SET_IS_APPSUMO_COUPON_APPLIED = "SET_IS_APPSUMO_COUPON_APPLIED",
    SET_INTEGRATION_ADD_ON = "SET_INTEGRATION_ADD_ON",
    SET_WHITE_LABEL_ADD_ON = "SET_WHITE_LABEL_ADD_ON",
    SET_IS_FULL_DISCOUNT = "SET_IS_FULL_DISCOUNT",
    SET_IS_SUBSCRIPTION_NAME = "SET_IS_SUBSCRIPTION_NAME",
    SET_IS_PAY_PER_USAGE_PLAN = "SET_IS_PAY_PER_USAGE_PLAN",
    SET_IS_LEGACY_PLAN = "SET_IS_LEGACY_PLAN",
    SET_CANDIDATE_NOTIFICATION = "SET_CANDIDATE_NOTIFICATION",
    SET_EMAIL_TEMPLATE_NOTIFICATION = "SET_EMAIL_TEMPLATE_NOTIFICATION",
    SET_ADMIN_NOTIFICATION = "SET_ADMIN_NOTIFICATION",
    SET_CUSTOM_TEMPLATE_ACTIVE = "SET_CUSTOM_TEMPLATE_ACTIVE",
    SET_SUPPORT_EMAIL = "SET_SUPPORT_EMAIL",
    SET_TERMS_URL = "SET_TERMS_URL",
    SET_PRIVACY_URL = "SET_PRIVACY_URL",
    SET_CUSTOM_IMAGE = "SET_CUSTOM_IMAGE",
    SET_DAYS_LEFT_TRIAL = "SET_DAYS_LEFT_TRIAL",
    SET_TRIAL_EXTENDED = "SET_TRIAL_EXTENDED",
    SET_WORKSPACE_TRIAL_PERIOD = "SET_WORKSPACE_TRIAL_PERIOD",
    SET_WORKSPACE_ADVANCE_PROCTORING = "SET_WORKSPACE_ADVANCE_PROCTORING",
    SET_WORKSPACE_WHITE_LABEL = "SET_WORKSPACE_WHITE_LABEL",
    SET_BRAND_TEXT_COLOR = "SET_BRAND_TEXT_COLOR",
}

export type Mutations = {
    [MutationType.SET_BRAND_COLOR](state: State, value: String): void
    [MutationType.SET_BRAND_TEXT_COLOR](state: State, value: String): void
    [MutationType.SET_WORKSPACE_URL](state: State, value: String): void
    [MutationType.SET_WORKSPACE_ID](state: State, value: String): void
    [MutationType.SET_WORKSPACE_NAME](state: State, value: String): void
    [MutationType.SET_WHITELABEL](state: State, value: Boolean): void
    [MutationType.SET_WORKSPACE_LOGO](state: State, value: String): void
    [MutationType.SET_WORKSPACE_FAVICON](state: State, value: String): void
    [MutationType.SET_SUBSCRIPTION_EXPIRE_DATE](
        state: State,
        value: String
    ): void
    [MutationType.SET_WORKSPACE_STATUS](state: State, value: String): void
    [MutationType.SET_IS_FULL_DISCOUNT](state: State, value: Boolean): void
    [MutationType.SET_IS_SUBSCRIPTION_NAME](state: State, value: String): void
    [MutationType.SET_IS_PAY_PER_USAGE_PLAN](state: State, value: Boolean): void
    [MutationType.SET_IS_LEGACY_PLAN](state: State, value: Boolean): void
    [MutationType.SET_IS_BILLING_ALLOWED](state: State, value: Boolean): void
    [MutationType.SET_IS_TRIAL](state: State, value: Boolean): void
    [MutationType.SET_IS_APPSUMO_COUPON_APPLIED](
        state: State,
        value: Boolean
    ): void
    [MutationType.SET_INTEGRATION_ADD_ON](state: State, value: Boolean): void
    [MutationType.SET_WHITE_LABEL_ADD_ON](state: State, value: Boolean): void
    [MutationType.SET_CANDIDATE_NOTIFICATION](
        state: State,
        value: Boolean
    ): void
    [MutationType.SET_ADMIN_NOTIFICATION](state: State, value: Boolean): void
    [MutationType.SET_CUSTOM_TEMPLATE_ACTIVE](
        state: State,
        value: Boolean
    ): void
    [MutationType.SET_EMAIL_TEMPLATE_NOTIFICATION](
        state: State,
        value: Boolean
    ): void
    [MutationType.SET_SUPPORT_EMAIL](state: State, value: String): void
    [MutationType.SET_TERMS_URL](state: State, value: String): void
    [MutationType.SET_PRIVACY_URL](state: State, value: String): void
    [MutationType.SET_CUSTOM_IMAGE](state: State, value: String): void
    [MutationType.SET_DAYS_LEFT_TRIAL](state: State, value: Number): void
    [MutationType.SET_TRIAL_EXTENDED](state: State, value: Boolean): void
    [MutationType.SET_WORKSPACE_TRIAL_PERIOD](
        state: State,
        value: Boolean
    ): void
    [MutationType.SET_WORKSPACE_ADVANCE_PROCTORING](
        state: State,
        value: Boolean
    ): void
    [MutationType.SET_WORKSPACE_WHITE_LABEL](state: State, value: Boolean): void
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationType.SET_WORKSPACE_URL](state, value: String) {
        state.workspaceUrl = value
    },
    [MutationType.SET_WORKSPACE_ID](state, value: String) {
        state.workspaceId = value
    },
    [MutationType.SET_WORKSPACE_NAME](state, value: String) {
        state.workspaceName = value
    },
    [MutationType.SET_WHITELABEL](state, value: Boolean) {
        state.isWhiteLabel = value
    },
    [MutationType.SET_WORKSPACE_LOGO](state, value: String) {
        state.workspaceLogo = value
    },
    [MutationType.SET_WORKSPACE_FAVICON](state, value: String) {
        state.WorkspaceFavicon = value
    },
    [MutationType.SET_SUBSCRIPTION_EXPIRE_DATE](state, value: String) {
        state.subscriptionExpireDate = value
    },
    [MutationType.SET_WORKSPACE_STATUS](state, value: String) {
        state.status = value
    },
    [MutationType.SET_IS_BILLING_ALLOWED](state, value: Boolean) {
        state.isBillingAllowed = value
    },
    [MutationType.SET_IS_TRIAL](state, value: Boolean) {
        state.isTrial = value
    },
    [MutationType.SET_IS_APPSUMO_COUPON_APPLIED](state, value: Boolean) {
        state.isAppSumoCouponApplied = value
    },
    [MutationType.SET_INTEGRATION_ADD_ON](state, value: Boolean) {
        state.integrationAddOn = value
    },
    [MutationType.SET_WHITE_LABEL_ADD_ON](state, value: Boolean) {
        state.whiteLabelAddOn = value
    },
    [MutationType.SET_IS_FULL_DISCOUNT](state, value: Boolean) {
        state.isFullDiscount = value
    },
    [MutationType.SET_IS_SUBSCRIPTION_NAME](state, value: String) {
        state.subscriptionName = value
    },
    [MutationType.SET_IS_PAY_PER_USAGE_PLAN](state, value: Boolean) {
        state.isPayPerUsagePlan = value
    },
    [MutationType.SET_IS_LEGACY_PLAN](state, value: Boolean) {
        state.isLegacyPlan = value
    },
    [MutationType.SET_BRAND_COLOR](state, color: string) {
        if (color) {
            if (color !== "#DE3439") {
                state.workspaceBrandColor = color
                state.workspaceDarkerBrandColor = getShadeColor(color, -30)
                let midBrandColor = getShadeColor(color, -15)
                state.workspaceMidBrandColor = midBrandColor + "4d"
                state.workspaceLighterBrandColor = color + "1a"
                state.workspaceDarkestBrandColor = color + "1a"
                state.workspaceLightGreyBrandColor = color + "1a"
            } else {
                state.workspaceBrandColor = "#DE3439"
                state.workspaceDarkerBrandColor = "#d9214e"
                state.workspaceMidBrandColor = "#fbf9f6"
                state.workspaceLighterBrandColor = "#fff"
                state.workspaceDarkestBrandColor = "#FFEBCC"
                state.workspaceLightGreyBrandColor = "#f3f6f9 "
            }
        }
    },
    [MutationType.SET_BRAND_TEXT_COLOR](state, value: String) {
        state.workspaceBrandTextColor = value
    },
    [MutationType.SET_CANDIDATE_NOTIFICATION](state, value: Boolean) {
        state.candidateNotificationAllowed = value
    },
    [MutationType.SET_ADMIN_NOTIFICATION](state, value: Boolean) {
        state.administratorNotificationAllowed = value
    },
    [MutationType.SET_EMAIL_TEMPLATE_NOTIFICATION](state, value: Boolean) {
        state.isEmailTemplateActive = value
    },
    [MutationType.SET_CUSTOM_TEMPLATE_ACTIVE](state, value: Boolean) {
        state.isCustomTemplateActive = value
    },
    [MutationType.SET_SUPPORT_EMAIL](state, value: String) {
        state.supportEmail = value
    },
    [MutationType.SET_TERMS_URL](state, value: String) {
        state.termsUrl = value
    },
    [MutationType.SET_PRIVACY_URL](state, value: String) {
        state.privacyUrl = value
    },
    [MutationType.SET_CUSTOM_IMAGE](state, value: String) {
        state.IntroductionImage = value
    },
    [MutationType.SET_DAYS_LEFT_TRIAL](state, value: Number) {
        state.daysLeftForTrial = value
    },
    [MutationType.SET_TRIAL_EXTENDED](state, value: Boolean) {
        state.trialExtended = value
    },
    [MutationType.SET_WORKSPACE_TRIAL_PERIOD](state, value: Boolean) {
        state.enableTrialperiod = value
    },
    [MutationType.SET_WORKSPACE_ADVANCE_PROCTORING](state, value: Boolean) {
        state.enableAdvanceProctoring = value
    },
    [MutationType.SET_WORKSPACE_WHITE_LABEL](state, value: Boolean) {
        state.enableWhiteLabel = value
    },
}
