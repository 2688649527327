import { computed } from "vue"
import { store } from "../store"
import { SUPPORT_EMAIL } from "@/core/constants"
import { ActionTypes as GlobalActions } from "@/store/modules/global/actions"

export default function ResellerHelper() {
    const getAuthUser = computed(() => store.getters.getAuthUser)
    const workspaceName = computed(() => store.getters.getWorkspaceName)
    const resellerName = computed(() => store.getters.getResellerName)
    const supportEmail = computed(() => store.getters.getSupportEmail)

    const checkResellers = (resellerType: string) => {
        const authUserEmail = getAuthUser.value?.email
        const isDefaultReseller = resellerType === "default"
        const isJoinReseller =
            resellerType === "Join" && authUserEmail !== SUPPORT_EMAIL

        store.dispatch(GlobalActions.SET_IS_DEFAULT_RESELLER, isDefaultReseller)
        store.dispatch(GlobalActions.SET_IS_RESELLER, isJoinReseller)
    }

    const setResellerName = (resellerName: string) => {
        if (resellerName) {
            store.dispatch(GlobalActions.SET_RESELLER_NAME, resellerName)
        } else {
            store.dispatch(GlobalActions.SET_RESELLER_NAME, "")
        }
    }

    const setResellerDomain = (resellerDomain: string) => {
        if (resellerDomain) {
            store.dispatch(GlobalActions.SET_RESELLER_DOMAIN, resellerDomain)
        } else {
            store.dispatch(GlobalActions.SET_RESELLER_DOMAIN, "")
        }
    }
    const setResellerStatus = (resellerStatus: string) => {
        store.dispatch(GlobalActions.SET_RESELLER_STATUS, resellerStatus)
    }
    const setLinkToResellerSubscriptionStatus = (isLinked: string) => {
        store.dispatch(
            GlobalActions.SET_LINK_TO_RESELLER_SUBSCRIPTION_STATUS,
            isLinked
        )
    }

    const setResellerManageWorkspaceStatus = (
        manageWorkspaceStatus: string
    ) => {
        if (manageWorkspaceStatus) {
            store.dispatch(
                GlobalActions.SET_RESELLER_MANAGE_WORKSPACE_STATUS,
                manageWorkspaceStatus
            )
        } else {
            store.dispatch(
                GlobalActions.SET_RESELLER_MANAGE_WORKSPACE_STATUS,
                ""
            )
        }
    }

    const mailToSuperAdmin = (val: string) => {
        const name =
            getAuthUser.value.firstName !== undefined
                ? (
                      getAuthUser.value.firstName +
                      " " +
                      getAuthUser.value.lastName
                  ).trim()
                : null

        const mailDataObj = {
            upgrade: {
                subject: `Upgrade my ${resellerName.value} subscription`,
                body: `Hi%20there%2C%0A%0ACan%20you%20help%20me%20upgrade%20my%20subscription%3F%20%0AWorkspace%20code%3A%20${workspaceName.value}%20%0A%0AThank%20You%2C%0A${name}`,
            },
            whitelabel: {
                subject: `Unlock white label for my ${resellerName.value} subscription`,
                body: `Hi%20there%2C%0A%0ACan%20you%20help%20me%20unlock%20the%20white%20label%20feature%3F%20%0AWorkspace%20code%3A%20${workspaceName.value}%20%0A%0AThank%20You%2C%0A${name}`,
            },
            proctoring: {
                subject: `Upgrade my ${resellerName.value} subscription`,
                body: `Hi%20there%2C%0A%0ACan%20you%20help%20me%20unlock%20the%20advanced%20proctoring%20feature%3F%20%0AWorkspace%20code%3A%20${workspaceName.value}%20%0A%0AThank%20You%2C%0A${name}`,
            },
        }

        const mailData = mailDataObj[val]
        window.location.href = `mailto:${supportEmail.value}?subject=${mailData.subject}&body=${mailData.body}`

        store.dispatch(GlobalActions.SHOW_SUBSCRIPTION_INACTIVE_MODAL, true)
    }

    return {
        checkResellers,
        setResellerName,
        setResellerDomain,
        setResellerStatus,
        setResellerManageWorkspaceStatus,
        mailToSuperAdmin,
        setLinkToResellerSubscriptionStatus,
    }
}
