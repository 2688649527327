import { useStore } from "@/store"
import { ActionTypes as AuthActions } from "@/store/modules/auth/actions"
import { useRouter } from "vue-router"
import { toast } from "vue3-toastify"
import "vue3-toastify/dist/index.css"
import i18n from "@/plugins/i18n"

const resellerAssessmentHosts = [
    "assessments.join-staging.com",
    "assessments.join.com",
]
const preventNotificationStatusCodes = [200, 204, 403, 401]

export default function useCatchHandler() {
    const store = useStore()
    const router = useRouter()
    const handleError = (response: any) => {
        const errors: any = response.data ? response!.data!.error : null
        let message
        if (errors) {
            const { statusCode } = errors
            if (errors.hasOwnProperty("details")) {
                message = errors.details[0].message
            } else {
                message = errors.message
            }
            if (statusCode === 401) {
                router.push({ name: "Login", query: { action: "clear" } })
                store.dispatch(AuthActions.LOG_OUT)
            }
            if (statusCode === 403 && message === "Record exist") {
                router.push({ name: "Login", query: { action: "clear" } })
                store.dispatch(AuthActions.LOG_OUT)
            }

            if (statusCode === 403 && message === "Record exist") {
                toast(`${message}`, {
                    type: "error",
                    autoClose: 4000,
                    position: "bottom-left",
                })
            }
            if (statusCode === 402 && message === "SUBSCRIPTION EXPIRED") {
                let isSubscriptionExpired = sessionStorage.getItem(
                    "isSubscriptionExpired"
                )
                if (isSubscriptionExpired === null) {
                    sessionStorage.setItem("isSubscriptionExpired", "true")
                    toast(`${i18n.global.t("subscription_expired.expired")}`, {
                        type: "error",
                        autoClose: 4000,
                        position: "bottom-left",
                    })
                }
            }
            if (statusCode === 403 && message === "Verification is mandatory") {
                toast(`${message}`, {
                    type: "error",
                    autoClose: 4000,
                    position: "bottom-left",
                })
            }
            if (statusCode === 403 && message === "Unverified user") {
                toast(`${i18n.global.t("dashboard_header.verify_error")}`, {
                    type: "error",
                    autoClose: 4000,
                    position: "bottom-left",
                })
            }
            if (statusCode === 400 && message === "Link Not found") {
                router.push({ name: "InvitationExpired" })
            }
            if (statusCode === 403 && message === "User Already Exists") {
                toast(`${i18n.global.t("dashboard_header.user_exist_error")}`, {
                    type: "error",
                    autoClose: 4000,
                    position: "bottom-left",
                })
            }
            if (
                statusCode === 401 &&
                !resellerAssessmentHosts.includes(window.location.host)
            ) {
                toast(`${message}`, {
                    type: "error",
                    autoClose: 4000,
                    position: "bottom-left",
                })
            }
            if (
                !preventNotificationStatusCodes.includes(statusCode) &&
                message !== "SUBSCRIPTION EXPIRED"
            ) {
                toast(`${message}`, {
                    type: "error",
                    autoClose: 4000,
                    position: "bottom-left",
                })
            }
        }
    }

    return { handleError }
}
