import DefaultFavicon from "@/assets/favicon-red.ico"
import JoinFavicon from "@/assets/join.ico"
import helperFunction from "@/helper-functions/helperFunctions"
import paths from "@/router/routes"
import useMixpanel from "@/services/analytics/mixpanel"
import { useStore } from "@/store"
import { ActionTypes as AuthActions } from "@/store/modules/auth/actions"
import { ActionTypes as GlobalActions } from "@/store/modules/global/actions"
import { ActionTypes as WorkspaceActions } from "@/store/modules/workspace/actions"
import moment from "moment/moment"
import { computed, ref } from "vue"
import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router"
import { SUPPORT_EMAIL } from "../core/constants"
const { initialise } = useMixpanel()
const { getMainDomain, setFavicon, initialCapitalize, setCanonical } =
    helperFunction()

const resellerHosts = ["assessments.join-staging.com", "assessments.join.com"]

const routes: Array<RouteRecordRaw> = [...paths]
const router = createRouter({
    history: createWebHistory(),
    routes,
})
// When ever we deploy anything to production, version number gets updated that why we get error in console
// This is to avoid that error and allow it to redirect to the correct page
router.onError((error: any, to) => {
    if (
        error.message.includes("Failed to fetch dynamically imported module") ||
        error.message.includes("Importing a module script failed")
    ) {
        window.location.href = to.fullPath
    }
})
const store = useStore()
const isWhiteLabel = computed(() => store.getters.getWhiteLabelStatus)
const favicon: any = computed(() => store.getters.getWorkspaceFavicon)
const isReseller = computed(() => store.getters.getResellerStatus)
const isPaidUser = computed(() => store.getters.getPaidUser)
const getAuthUser = computed(() => store.getters.getAuthUser)
const isResellerActive = computed(() => store.getters.getisResellerActive)
const subscriptionExpireDate: any = computed(
    () => store.getters.getSubscriptionExpireDate
)

// keep track of last visited href to avoid sending data to mixpanel twice
let lastVisitedHref = ""

router.afterEach((to, from) => {
    // SECTION START - DO NOT EDIT

    // reset sessionId when user lands on login/register
    const comingFromAuth =
        from.matched.length > 0 &&
        ["/login", "/register"].includes(from.matched[0].path)
    const goingToAuth = ["/login", "/register"].includes(to.matched[0].path)
    if (goingToAuth && !comingFromAuth) {
        store.dispatch(GlobalActions.SET_SESSION_ID, undefined)
        store.dispatch(WorkspaceActions.SET_WORKSPACE_NAME, "")
        store.dispatch(WorkspaceActions.SET_IS_SUBSCRIPTION_NAME, "")
    }

    // trigger event on each route change
    const properties = { route: to.matched[0].path }
    for (const key in to.query) {
        properties[key] = Array.isArray(to.query[key])
            ? to.query[key][0]
            : to.query[key]
    }
    for (const key in to.params) {
        properties[key] = Array.isArray(to.params[key])
            ? to.params[key][0]
            : to.params[key]
    }

    if (lastVisitedHref !== window.location.href) {
        window.collectEvent("RouteChange", properties)
    }
    lastVisitedHref = window.location.href
    // SECTION END

    if (typeof window.Intercom === "function") {
        // update intercom on route change
        window.Intercom("update")
    }
})

router.beforeEach((to, from) => {
    const canonicalUrl = `${window.location.origin}${to.fullPath}`
    setCanonical(canonicalUrl)
    initialise()
    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0)
    }, 100)
    const accessToken = to?.query?.accessToken
    const workspaceUrl = to?.query?.wu
    const routeContainsCreds = accessToken && workspaceUrl

    let isAuthRoute = to.meta.isAuth
    const isCreateWorkspaceRoute = to.query.isCreateWorkspace === "true"
    const isUserLoggedIn = computed(
        () => !routeContainsCreds && store.getters.getSessionKey
    )

    if (!window.location.host.includes("cdn.testlify.com")) {
        if (
            window.location.host.includes("testlify.com") &&
            !window.location.host.includes("app.testlify.com")
        ) {
            let url = window.location.href.split(".")
            url.shift()
            window.location.href = "https://app." + url.join(".")
        }
    }

    if (resellerHosts.includes(window.location.host)) {
        document.title = "JOIN"
    } else if (
        window.location.origin.includes("testlify") ||
        window.location.origin.includes("localhost")
    ) {
        document.title = "Testlify"
    } else {
        document.title = initialCapitalize(
            getMainDomain(window.location.hostname)
        )
    }
    if (isUserLoggedIn.value) {
        SetFaviconRoute()
    }
    //if user is not logged in  and the url is careers.testlify.com/login
    // then it will redirect to app.testlify.com/login
    if (
        !isUserLoggedIn.value &&
        window.location.href.includes(".testlify.com") &&
        !window.location.host.includes("app.") &&
        to.name === "Login"
    ) {
        window.location.href = "https://app.testlify.com/login"
    } else if (to.name === "Login") {
        localStorage.removeItem("workspace")
    }

    // In intermediate login if url has isCreateWorkspace === "true" then we need to logout from the current logged in domain otherwise,
    // it will redirect to "AssessemntMain" page
    // This will allow us to redirect to createWorkspace page
    if (isCreateWorkspaceRoute) {
        store.dispatch(AuthActions.EMPTY_API_ACCESS_REGISTRATION)
        // this is especially used for Switch Workspace feature
        store.dispatch(AuthActions.EMPTY_AUTH_USER)
    }
    if (subscriptionExpireDate.value !== "" && isUserLoggedIn.value) {
        const todayDate = moment(new Date().toUTCString())
        const expiryDate = moment(
            new Date(subscriptionExpireDate.value).toUTCString()
        )
        const preciseDay = ref(expiryDate.diff(todayDate, "days", true))
        const remainingDays = ref(Number(preciseDay.value))

        if (
            getAuthUser.value.email &&
            getAuthUser.value.email !== SUPPORT_EMAIL &&
            remainingDays.value <= 0 &&
            !isAuthRoute &&
            !isResellerActive.value
        ) {
            if (
                to.name !== "CreateNewWorkspaceForm" &&
                to.name !== "ChoosePlan" &&
                to.name !== "BillingPage"
            ) {
                if (
                    to.name !== "SubscriptionExpired" &&
                    to.name !== "SwitchWorkspace"
                ) {
                    return { name: "SubscriptionExpired" }
                } else if (!to.meta.isBilling) {
                    if (isPaidUser.value) {
                        return { name: "BillingInformation" }
                    }
                    if (getAuthUser.value.email != "support@testlify.com") {
                        return { name: "ChoosePlan" }
                    }
                }
            }
        }
    }
    if (
        isUserLoggedIn.value &&
        isAuthRoute &&
        to.name !== "InviteeRegister" &&
        to.name !== "MobileVerification" &&
        to.name !== "EmailVerification"
    ) {
        return { name: "AssessmentMain" }
    } else if (
        !isUserLoggedIn.value &&
        !isAuthRoute &&
        window.history.state.back !== "/register" &&
        !window.history.state.current.includes("/talents?candidateId=")
    ) {
        return true
    } else if (
        !isUserLoggedIn.value &&
        !isAuthRoute &&
        window.history.state.current.includes("/talents?candidateId=")
    ) {
        let redirectAfterLoginDetails = {
            name: to.name.toString(),
            params: to.query,
        }
        store.dispatch(
            AuthActions.REDIRECT_AFTER_LOGIN,
            redirectAfterLoginDetails
        )
        return { name: "Login" }
    } else if (!isUserLoggedIn.value && !isAuthRoute) {
        // In cases where the user isn't logged in and landed with another URL, store the URL where the user landed and redirect after login
        let redirectAfterLoginDetails = {
            name: to.name.toString(),
            params: to.params,
        }
        store.dispatch(
            AuthActions.REDIRECT_AFTER_LOGIN,
            redirectAfterLoginDetails
        )
        return { name: "Login" }
    } else return true
})

const SetFaviconRoute = () => {
    if (isWhiteLabel.value && !isReseller.value) {
        if (favicon.value) {
            setFavicon(
                //bearer:disable javascript_lang_insufficiently_random_values
                favicon.value + "?" + Math.random().toString(36).substr(2, 10)
            )
        }
    } else {
        if (isReseller.value) {
            //bearer:disable javascript_lang_insufficiently_random_values
            setFavicon(
                JoinFavicon + "?" + Math.random().toString(36).substr(2, 10)
            )
        } else {
            //bearer:disable javascript_lang_insufficiently_random_values
            setFavicon(
                DefaultFavicon + "?" + Math.random().toString(36).substr(2, 10)
            )
        }
    }
}

export default router
