import { RootState } from "@/store"
import { GetterTree } from "vuex"
import { State } from "./state"

export type Getters = {
    getWorkspaceUrl(state: State): String
    getWorkspaceId(state: State): String
    getWorkspaceName(state: State): String
    getWorkspaceLogo(state: State): String
    getWorkspaceFavicon(state: State): String
    getSubscriptionExpireDate(state: State): String
    getWorkspaceStatus(state: State): String
    getWhiteLabelBrandColor(state: State): String
    getWhiteLabelBrandTextColor(state: State): String
    getWhiteLabelDarkerBrandColor(state: State): String
    getWhiteLabelMidBrandColor(state: State): String
    getWhiteLabelLighterBrandColor(state: State): String
    getWhiteLabelDarkestBrandColor(state: State): String
    getWhiteLabelLightGreyBrandColor(state: State): String
    getWhiteLabelStatus(state: State): Boolean
    isTrial(state: State): Boolean
    getDaysLeftForTrial(state: State): Number
    getTrialExtended(state: State): Boolean
    integrationAddOn(state: State): Boolean
    getAppSumoCouponStatus(state: State): Boolean
    isBillingAllowed(state: State): Boolean
    isFullDiscount(state: State): Boolean
    isPayPerUsagePlan(state: State): Boolean
    isLegacyPlan(state: State): Boolean
    subscriptionName(state: State): String
    whiteLabelAddOn(state: State): Boolean
    getCandidateNotificationStatus(state: State): Boolean
    getCustomEmailTemplateStatus(state: State): Boolean
    getAdminNotificationStatus(status: State): Boolean
    getEmailTemplateNotification(state: State): Boolean
    getSupportEmail(state: State): String
    getTermsUrl(state: State): String
    getPrivacyUrl(state: State): String
    getIntroductionImage(state: State): String
    getWorkspaceTrialPeriod(state: State): Boolean
    getWorkspaceAdvanceProctoring(state: State): Boolean
    getWorkspaceWhiteLabel(state: State): Boolean
}

export const getters: GetterTree<State, RootState> & Getters = {
    getWorkspaceId(state) {
        return state.workspaceId
    },
    getWorkspaceUrl(state) {
        return state.workspaceUrl
    },
    getWhiteLabelStatus(state) {
        return state.isWhiteLabel
    },
    getWorkspaceName(state) {
        return state.workspaceName
    },
    getWorkspaceLogo(state) {
        return state.workspaceLogo
    },
    getWorkspaceFavicon(state) {
        return state.WorkspaceFavicon
    },
    getSubscriptionExpireDate(state) {
        return state.subscriptionExpireDate
    },
    getWorkspaceStatus(state) {
        return state.status
    },
    getWhiteLabelBrandColor(state) {
        return state.workspaceBrandColor
    },
    getWhiteLabelBrandTextColor(state) {
        return state.workspaceBrandTextColor
    },
    getWhiteLabelDarkerBrandColor(state) {
        return state.workspaceDarkerBrandColor
    },
    getWhiteLabelMidBrandColor(state) {
        return state.workspaceMidBrandColor
    },
    getWhiteLabelLighterBrandColor(state) {
        return state.workspaceLighterBrandColor
    },
    getWhiteLabelDarkestBrandColor(state) {
        return state.workspaceDarkestBrandColor
    },
    getWhiteLabelLightGreyBrandColor(state) {
        return state.workspaceLightGreyBrandColor
    },
    isTrial(state) {
        return state.isTrial
    },
    integrationAddOn(state) {
        return state.integrationAddOn
    },
    getAppSumoCouponStatus(state) {
        return state.isAppSumoCouponApplied
    },
    whiteLabelAddOn(state) {
        return state.whiteLabelAddOn
    },
    isBillingAllowed(state) {
        return state.isBillingAllowed
    },
    isFullDiscount(state) {
        return state.isFullDiscount
    },
    isPayPerUsagePlan(state) {
        return state.isPayPerUsagePlan
    },
    isLegacyPlan(state) {
        return state.isLegacyPlan
    },
    subscriptionName(state) {
        return state.subscriptionName
    },
    getCandidateNotificationStatus(state) {
        return state.candidateNotificationAllowed
    },
    getDaysLeftForTrial(state) {
        return state.daysLeftForTrial
    },
    getTrialExtended(state) {
        return state.trialExtended
    },
    getAdminNotificationStatus(state) {
        return state.administratorNotificationAllowed
    },
    getCustomEmailTemplateStatus(state) {
        return state.isCustomTemplateActive
    },
    getEmailTemplateNotification(state) {
        return state.isEmailTemplateActive
    },
    getSupportEmail(state) {
        return state.supportEmail
    },
    getTermsUrl(state) {
        return state.termsUrl
    },
    getPrivacyUrl(state) {
        return state.privacyUrl
    },
    getIntroductionImage(state) {
        return state.IntroductionImage
    },
    getWorkspaceTrialPeriod(state) {
        return state.enableTrialperiod
    },
    getWorkspaceAdvanceProctoring(state) {
        return state.enableAdvanceProctoring
    },
    getWorkspaceWhiteLabel(state) {
        return state.enableWhiteLabel
    },
}
