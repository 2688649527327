/*List of Enums*/
import i18n from "@/plugins/i18n"
const CHANGE_USER_STATUS = {
    ACTIVATED: { name: i18n.global.t("constants_text.activated") },
    DEACTIVATED: { name: i18n.global.t("constants_text.deactivated") },
}
const USER_STATUS = {
    INVITED: { name: "Invited" },
    ACTIVATED: { name: "Activated" },
    DEACTIVATED: { name: "Deactivated" },
}
const CUSTOM_EMAILS = {
    INVITATION: {
        name: i18n.global.t("notification_tab.drop_dawn_text.invitation"),
    },
    REJECTION: {
        name: i18n.global.t("notification_tab.drop_dawn_text.rejection"),
    },
    RESULT: {
        name: i18n.global.t("notification_tab.drop_dawn_text.result"),
    },
    COMPLETION: {
        name: i18n.global.t("notification_tab.drop_dawn_text.completion"),
    },
    REMINDER: {
        name: i18n.global.t("notification_tab.drop_dawn_text.reminder"),
    },
}
const QUESTION_LAYOUT = [
    {
        id: 1,
        value: "HORIZONTAL",
        name: `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 25" fill="none">
<rect x="3.85" y="4.51675" width="16.3" height="16.3" rx="1.15" stroke="#6D6D80" stroke-width="1.7"/>
<path d="M12 17.6667V7.66675C12 7.11446 12.4477 6.66675 13 6.66675H17C17.5523 6.66675 18 7.11446 18 7.66675V17.6667C18 18.219 17.5523 18.6667 17 18.6667H13C12.4477 18.6667 12 18.219 12 17.6667Z" fill="#6D6D80"/>
</svg> ${i18n.global.t("custom_que.horizontal_layout")}`,
    },
    {
        id: 2,
        value: "VERTICAL",
        name: `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 25" fill="none">
<rect x="20.15" y="4.51675" width="16.3" height="16.3" rx="1.15" transform="rotate(90 20.15 4.51675)" stroke="#6D6D80" stroke-width="1.7"/>
<path d="M7 12.6667L17 12.6667C17.5523 12.6667 18 13.1145 18 13.6667V17.6667C18 18.219 17.5523 18.6667 17 18.6667H7C6.44771 18.6667 6 18.219 6 17.6667L6 13.6667C6 13.1145 6.44771 12.6667 7 12.6667Z" fill="#6D6D80"/>
</svg> ${i18n.global.t("custom_que.vertical_layout")}`,
    },
]

const USER_ROLE = {
    OWNER: { name: "Owner" },
    ADMIN: { name: "Admin" },
    MEMBER: { name: "Member" },
}

const EN_CURRENCY = {
    INR: "en-IN",
    USD: "en-US",
    GBP: "en-GB",
}
const STATUS_COLORS = {
    INVITED: "blck-txt-clr",
    RE_INVITED: "blck-txt-clr",
    COMPLETED: "grn-clr-txt",
    IN_PROGRESS: "lght-ylw-clr-txt",
    ENROLLED: "lght-ylw-clr-txt",
    DISQUALIFIED: "red-clr-txt",
    REJECTED: "red-clr-txt",
    INVITATION_EXPIRED: "red-clr-txt",
    REMINDER_SENT_MANUALLY: "blck-txt-clr",
    REMINDER_SENT_BY_SYSTEM: "blck-txt-clr",
    TEST_RESULT_SENT_MANUALLY: "blck-txt-clr",
    TEST_RESULT_SENT_BY_SYSTEM: "blck-txt-clr",
    TERMINATED: "red-clr-txt",
}

const CANDIDATE_STAGES = {
    NOT_YET_EVALUATED: { name: "NOT YET EVALUATED" },
    EVALUATED: { name: "EVALUATED" },
    INVITED_FOR_ASSESSMENT: { name: "INVITED FOR ASSESSMENT" },
    ENROLLED_FOR_ASSESSMENT: { name: "ENROLLED FOR ASSESSMENT" },
    ASSESSMENT_COMPLETED: { name: "ASSESSMENT COMPLETED" },
    SHORTLISTED: { name: "SHORTLISTED" },
    INVITED_FOR_INTERVIEW: { name: "INVITED FOR INTERVIEW" },
    REFERENCE_VERIFIED: { name: "REFERENCE VERIFIED" },
    OFFER_SENT: { name: "OFFER SENT" },
    OFFER_DECLINED: { name: "OFFER DECLINED" },
    CANDIDATE_WITHDREW: { name: "CANDIDATE WITHDREW" },
    CANDIDATE_UNRESPONSIVE: { name: "CANDIDATE UNRESPONSIVE" },
    REJECTED: { name: "REJECTED" },
    HIRED: { name: "HIRED" },
}
const PRODCODINGTESTID = "63480d3317693702adfa5178"
const STAGINGCODINGTESTID = "630f5153aa091fd0cd1cc83c"

const LTI_ORG_ID = "663b7bc129be1f46834c47b6"

const INTEGRATIONHOST = "https://integrations.testlify.dev"

const NOLIMITDURATION = 259200
const CUSTOM_QUESTION_DURATION = [
    { id: 1, value: 10, name: "10 " + i18n.global.t("constants_text.seconds") },
    { id: 2, value: 30, name: "30 " + i18n.global.t("constants_text.seconds") },
    { id: 3, value: 60, name: "1 " + i18n.global.t("constants_text.minute") },
    { id: 4, value: 120, name: "2 " + i18n.global.t("constants_text.minutes") },
    { id: 5, value: 300, name: "5 " + i18n.global.t("constants_text.minutes") },
    {
        id: 6,
        value: 600,
        name: "10 " + i18n.global.t("constants_text.minutes"),
    },
    {
        id: 7,
        value: 1800,
        name: "30 " + i18n.global.t("constants_text.minutes"),
    },
    { id: 8, value: 1, name: i18n.global.t("constants_text.custom") },
    { id: 9, value: 3600, name: i18n.global.t("constants_text.no_limit") },
]

const EXHAUSTED_THE_LIMIT =
    "You've exhausted the limit. Please wait for one minute and try refreshing the page to solve the problem."
const COMPANY_QUESTIONS_DURATION_LIST = [
    {
        id: 1,
        value: 10,
        name: "10 " + i18n.global.t("constants_text.seconds"),
    },
    {
        id: 2,
        value: 30,
        name: "30 " + i18n.global.t("constants_text.seconds"),
    },
    {
        id: 3,
        value: 60,
        name: "1 " + i18n.global.t("constants_text.minute"),
    },
    {
        id: 4,
        value: 120,
        name: "2 " + i18n.global.t("constants_text.minutes"),
    },
    {
        id: 5,
        value: 300,
        name: "5 " + i18n.global.t("constants_text.minutes"),
    },
    { id: 8, value: 1, name: i18n.global.t("constants_text.custom") },
    {
        id: 9,
        value: 259200,
        name: i18n.global.t("constants_text.no_limit"),
    },
]
export {
    CANDIDATE_STAGES,
    CHANGE_USER_STATUS,
    COMPANY_QUESTIONS_DURATION_LIST,
    CUSTOM_EMAILS,
    CUSTOM_QUESTION_DURATION,
    EN_CURRENCY,
    EXHAUSTED_THE_LIMIT,
    INTEGRATIONHOST,
    LTI_ORG_ID,
    NOLIMITDURATION,
    PRODCODINGTESTID,
    QUESTION_LAYOUT,
    STAGINGCODINGTESTID,
    STATUS_COLORS,
    USER_ROLE,
    USER_STATUS,
}
export const ADP_DEFUALT_VISIBLE_COLUMNS = [
    "name",
    "invitedAt",
    "avgScorePercentage",
    "phone",
    "grading",
    "reviewRating",
    "candidateStatus",
    "candidateStage",
    "candidateGrading",
    "action",
]
export const ALP_DEFUALT_VISIBLE_COLUMNS = ["createdOn", "createdBy"]
export const ADP_DEFAULT_SORT = {
    column: "invitedAt",
    order: "descending",
}

export const DEFAULT_ASSESSMENT_CONFIG = {
    mandatoryTestLibrary: false,
    mandatoryCamMic: false,
    capture30sec: false,
    customRedirectingPage: "",
    redirectMethod: "DEFAULT_REDIRECT_PAGE",
    assessmentStatus: "",
    defaultLanguage: "en",
    skipRegistration: false,
    recipientList: [],
    testAdministratorList: "",
    isMultipleMonitorEnabled: false,
    enableGrammarly: false,
    disableFullScreen: false,
    logGenerationRequired: false,
    forceFullScreen: false,
    sendAssessmentResultEmail: false,
    invitationEmailLinkValidity: 90,
    AssessmentDeadlineType: "NO_OF_DAYS",
    invitationEmailLinkExpiryDate: "",
    assessmentDeadlineTz: "",
    customFields: [],
    numberOfAttempts: 1,
    assessmentInstructions: "",
    enableNavigationToPreviousQuestions: true,
    enableFeedbackAfterSection: false,
    screenShare: false,
    snapshotIntervalType: "DEFAULT_SNAPSHOT_INTERVAL",
    customSnapshotInterval: 120,
    dualCameraProctoring: false,
    candidateAccommodation: false,
    disableMobileAndTabletDevices: false,
    enabledPracticeTest: false,
    violation: {
        tabSwitch: {
            warning: false,
            limit: 5,
            tolerance: 5,
        },
        fullScreenExit: {
            warning: false,
            limit: 5,
            tolerance: 5,
        },
        photoAnalysis: {
            warning: false,
            limit: 5,
            tolerance: 5,
        },
        copyPaste: {
            warning: false,
            limit: 5,
        },
        mouseOut: {
            warning: false,
            limit: 0,
            tolerance: 5,
        },
        aiPlugin: {
            warning: false,
            limit: 5,
        },
    },
    isHonestyAgreement: false,
    customHonestyAgreement: "",
    verifyPrivateInvite: false,
    verifyPassword: "",
    restrictionType: "",
    restrictedDomains: [],
}

export const BIG_FIVE_TEST_LIBRARY_SUBTYPE = "6448dfc64c0a8d580231dbc0"

export const INTERVU_WORKSPACE_ID = "66b6177efefa40384c1fa072"

export const MOTIVATIONAL_TRAITS_TEST = "6617a9734fddf835c4271085"

export const SALES_PROFILER_TEST_LIBRARY_SUBTYPE = "660cfb75485e45fd331699fa"

export const PERSONALITY_16_TRAITS_TEST_LIBRARY_SUBTYPE =
    "660a8e8f51d62ad7a7fc684c"

export const LEADERSHIP_STYLE_TEST = "6613d8c879fde53b5ca57980"

export const SELF_ESTEEM = "6602d90a279d1cd28c5d5690"

export const BIG_5_PERSONALITY_TEST_LIBRARY_SUBTYPE = "66193934100287eb204d4f5a"
export const SMART_TEST_LIBRARY_SUBTYPE = "64467b2c6fe15ecd7a67446a"

export const DISC_TEST_LIBRARY_SUBTYPE = "64cd10bf98d335f6c859838c"
export const CULTURAL_FIT_TEST_LIBRARY_SUBTYPE = "65395d6d527f5b1f26c65960"
export const ENNEAGRAM_TEST_LIBRARY_SUBTYPE = "65b8aa4af7cee19075924d9b"
export const SUPPORT_EMAIL = "support@testlify.com"
export const HONESTY_AGREEMENT_MESSAGE =
    "Our system is committed to ensuring a fair and transparent assessment process for all candidates. We employ advanced techniques to detect any form of dishonesty and to maintain the integrity of our assessments. Any instance of dishonest behaviour may result in disqualification from the assessment and our platform, and such cases may be reported to the requesting employer.\n\nUsing AI tools, attempting the assessment multiple times, and other behaviour as outlined in our comprehensive policy are strictly prohibited unless explicitly permitted.\n\nPlease confirm your adherence to this policy by acknowledging below."
export const DEFAULT_SHORTLIST_TEMPLATE =
    "\n\nWe are pleased to inform you that you have been shortlisted for the next phase of our selection process. Thank you for your interest in WORKSPACE_NAME and for the time and effort you've put into your application.\n\nWe will be in touch shortly with further instructions on the upcoming steps. We look forward to getting to know you better and exploring the possibility of you joining our team.\n \nBest regards\n"
export const SAWAEED_EMPLOYER_DOMAIN = "https://app.tamayaz.ai"

export const HONESTY_AGREEMENT_WHITELABEL_MESSAGE =
    "Our system is committed to ensuring a fair and transparent assessment process for all candidates. We employ advanced techniques to detect any form of dishonesty and to maintain the integrity of our assessments. Any instance of dishonest behaviour may result in disqualification from the assessment and our platform, and such cases may be reported to the requesting employer.\n\nUsing AI tools, attempting the assessment multiple times, and other behaviour that is considered as a cheating are strictly prohibited unless explicitly permitted.\n\nPlease confirm your adherence to this policy by acknowledging below."
export const PERSONAL_EMAIL_DOMAINS = ["gmail.com", "yahoo.com", "hotmail.com"]
export const QUESTION_GUIDELINES_LINK =
    "https://help.testlify.com/en/articles/9942427-guidelines-for-creating-custom-questions"

export const VIDEO_DELETED_AFTER_90_DAYS_LINK =
    "https://help.testlify.com/en/articles/10345961-retention-policy-for-audio-and-video-recordings-in-assessments"
export const QUESTION_LIST_TYPE = [
    {
        id: 1,
        value: "SINGLE_SELECT",
        name: i18n.global.t("constants_text.single_select"),
    },
    {
        id: 2,
        value: "WRITTEN_ANSWER",
        name: i18n.global.t("constants_text.open_ended"),
    },
    {
        id: 3,
        value: "VIDEO_ANSWER",
        name: i18n.global.t("constants_text.video"),
    },
]

export const TIME_DROPDOWN = [
    {
        id: 0,
        value: "Custom",
        name: i18n.global.t("constants_text.custom"),
    },
    {
        id: 1,
        value: "No limit",
        name: i18n.global.t("constants_text.no_limit"),
    },
]

export const QUESTION_TYPES = [
    {
        id: 0,
        value: "ALL",
        name: i18n.global.t("constants_text.all"),
    },
    {
        id: 1,
        value: "CUSTOM",
        name: i18n.global.t("constants_text.custom"),
    },
]

export const DIFFICULTY_LEVEL_LIST = [
    {
        id: 1,
        value: "BEGINNER",
        name: i18n.global.t("constants_text.beginner"),
    },
    {
        id: 2,
        value: "INTERMEDIATE",
        name: i18n.global.t("constants_text.intermediate"),
    },
    {
        id: 3,
        value: "ADVANCED",
        name: i18n.global.t("constants_text.advanced"),
    },
    {
        id: 4,
        value: "CHALLENGING",
        name: i18n.global.t("constants_text.expert"),
    },
    {
        id: 5,
        value: "NOT_APPLICABLE",
        name: i18n.global.t("generic_test.not_applicable"),
    },
]

export const TIMER_TEST_TYPE = [
    // {
    //     id: 1,
    //     value: "Question Level",
    //     name: i18n.global.t("configuration_tab.que_level"),
    // },
    {
        id: 2,
        value: "Test Level",
        name: i18n.global.t("configuration_tab.test_level"),
    },
]

export const PRIVACY_POLICY_URL = "https://testlify.com/privacy-policy/"
export const TERMS_CONDITION_URL = "https://testlify.com/terms/"

export const S3DOMAINS = [
    "cdn.",
    "cdn-global.",
    "testlify-eu",
    "testlify-eu-staging",
]

export const ALBAYANASSESSMENT_DOMAIN = "https://app.albayanassessment.com"

export const durationOptions = [
    {
        id: 1,
        value: 10,
        name: "10 " + i18n.global.t("constants_text.seconds"),
    },
    {
        id: 2,
        value: 30,
        name: "30 " + i18n.global.t("constants_text.seconds"),
    },
    {
        id: 3,
        value: 60,
        name: "1 " + i18n.global.t("constants_text.minute"),
    },
    {
        id: 4,
        value: 120,
        name: "2 " + i18n.global.t("constants_text.minutes"),
    },
    {
        id: 5,
        value: 300,
        name: "5 " + i18n.global.t("constants_text.minutes"),
    },
    { id: 8, value: 1, name: i18n.global.t("constants_text.custom") },
    {
        id: 9,
        value: 0,
        name: i18n.global.t("constants_text.no_limit"),
    },
]
